import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Img from "gatsby-image";

import "../sass/pages/care-instructions.scss";

const AboutMe = ({ data }) => (
	<div className="wrapper-main">
		<SEO title="Care instructions" />
		<div>
			<h2>Care instructions</h2>
			<p>
				Jewelry should maintain in perfect conditions but it needs the proper
				care by its owner. Please follow the care instructions below.
			</p>
			<ul>
				<li>
					Avoid contact with water and other substances or products of personal
					use (such as perfume, creams, splash, sprays, etc.) Put on your
					jewelry after using any of the above.
				</li>
				<li>
					Avoid touching your jewelry with dirty hands (also creamy or oily/
					grease).
				</li>
				<li>
					You can clean the surface of your jewelry with a dry flannel, no need
					to rub, just clean littetly.
				</li>
				<li>
					Avoid storing your jewelry in a humid space. Or any kind of storage
					that could produce it. It is always best to store your jewelry
					individually or in a way pieces stay apart from each other to avoid
					scratches.
				</li>
				<li>
					It is not recommended to do sports, shower, swim or sleep with
					jewelry.
				</li>
				<li>
					The ph of each person can also influence the way jewelry maintains
					though time.
				</li>
			</ul>
			<p>
				If you have purchased one of my art pieces and wish to give maintenance
				to it please send me an email to info@fiorellapattoni.com
			</p>
		</div>
	</div>
);

export const query = graphql`
	query {
		calando: file(relativePath: { eq: "calando.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		ambar: file(relativePath: { eq: "ambar-rojo.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

export default AboutMe;
